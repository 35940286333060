<template>
  <div class="delivery-advertisement-container">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="genera-breadcrumb"
    >
      <el-breadcrumb-item>直播营销</el-breadcrumb-item>
      <el-breadcrumb-item>直播广告宣传</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="advertisement-title">
      <span style="font-weight: bold; font-size: 16px; color: #ffffff"
        >直播带货</span
      >
      <span class="iconfont" style="margin-left: 14px; color: #4b45ff"
        >&#xe61f;</span
      >
      <span style="font-size: 14px; margin-left: 9px; color: rgb(235, 235, 235)"
        >通投广告</span
      >
    </div>
    <!-- 上方按钮 -->
    <div class="main-btn">
      <el-button type="primary" style="background: #4b45ff" @click="goTo"
        ><span class="iconfont">&#xe608;</span> 新建计划</el-button
      >
      <el-button
        type="danger"
        style="background: rgb(255 62 108 / 10%); border-color: #ff003c"
        plain
        @click="handleClick_del"
        >删除</el-button
      >
      <span
        style="
          color: #ffffff;
          font-size: 16px;
          float: right;
          padding-right: 20px;
        "
        >推广余额：{{ money }}</span
      >
    </div>
    <!-- 表格主体 -->
    <div class="advertisement-main">
      <!-- 表格 -->
      <template>
        <el-table
          ref="multipleTable"
          :data="infoList"
          tooltip-effect="dark"
          style="width: 100%; flex: 1"
          height="1%"
          @selection-change="handleSelectionChange"
          :header-cell-style="{
            fontWeight: 'normal',
            height: '60px',
            color: '#fff',
            fontSize: '14px',
            background: '#0C0E3F',
          }"
          :cell-style="{
            fontSize: '14px',
            color: '#fff',
            height: '80px',
            background: '#070932',
          }"
        >
          <el-table-column
            type="selection"
            label="全选"
            width="45"
          ></el-table-column>
          <el-table-column :label="checkData" min-width="30px">
          </el-table-column>
          <el-table-column label="计划名称" min-width="65px">
            <template slot-scope="scope">{{
              scope.row.advertising_student_config_name
            }}</template>
          </el-table-column>
          <el-table-column prop="price" label="出价（元）" align="center">
          </el-table-column>
          <el-table-column prop="budget" label="预算（元）" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="editBtn(scope.row)" type="text" size="small"
                >编辑</el-button
              >
              <el-button
                @click="delBtn(scope.row)"
                type="text"
                style="color: red"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="pages-center"
          :current-page="listPages.currentPageNum"
          :page-size="listPages.eachPageNum"
          layout="prev, pager, next, jumper"
          :total="listPages.total"
          @current-change="pageCurrentChange"
        >
        </el-pagination>
      </template>
    </div>
  </div>
</template>

<script>
import { showAdvertisingListData, delAdvertisingData } from "@/utils/apis";
export default {
  data() {
    return {
      money: "",
      checkData: "全选（已选0个）",
      delTotal: void 0,
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      infoList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取广告投放列表数据
    getList() {
      let params = {
        paging: "1",
        pageSize: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
      };
      showAdvertisingListData(params)
        .then((res) => {
          if (res.code === 200) {
            this.infoList = res.data.list;
            this.listPages.total = res.data.total;
            this.money = res.data.money;
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
          });
        });
    },
    // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.getList();
    },
    // 编辑
    editBtn(row) {
      this.$router.push({
        path: "/liveExpand/adCreate",
        query: {
          id: row.advertising_student_config_id,
        },
      });
    },
    // 删除单条
    delBtn(row) {
      let params = {
        advertising_student_config_ids: row.advertising_student_config_id,
      };
      this.$confirm("确定删除数据？", "提示", {
        confimButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "msgBoxClass",
      })
        .then(() => {
          delAdvertisingData(params)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.getList();
              }
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err,
              });
            });
        })
        .catch(() => {
          this.$message.info("取消删除");
          this.$refs.multipleTable.clearSelection();
        });
    },
    // 跳转
    goTo() {
      this.$router.push({ path: "/liveExpand/adCreate" });
    },
    // 勾选数据后，表头显示已勾选数量,删除提示数量
    handleSelectionChange(e) {
      this.checkData = `全选（已选${e.length}个）`;
      this.delTotal = e.length;
    },
    //获取单行数据
    handleClick_del() {
      let id = this.$refs.multipleTable.selection
        .map((item) => item.advertising_student_config_id)
        .join(",");
      let params = {
        advertising_student_config_ids: id,
      };
      if (this.$refs.multipleTable.selection.length > 0) {
        this.$confirm(
          `是否确定删除选中的${this.delTotal}个数据，删除后将无法恢复`,
          "提示",
          {
            dangerouslyUseHTMLString: true,
            confimButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            customClass: "msgBoxClass",
          }
        )
          .then(() => {
            delAdvertisingData(params).then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.getList();
              }
            });
          })
          .catch(() => {
            this.$message.info("取消删除");
            this.$refs.multipleTable.clearSelection();
          });
      } else {
        this.$message({ type: "error", message: "请选中要删除的数据" });
      }
    },
  },
};
</script>
<style lang="scss">
.msgBoxClass {
  background-color: #0c0e3f !important;
  border: 1px solid #0c0e3f !important;
  p,
  .el-message-box__title {
    color: #ffffff;
  }
  .el-message-box__title::before {
    display: inline-block;
    content: "";
    width: 4px;
    height: 12px;
    margin-right: 10px;
    background: #4b45ff;
  }
  .el-icon-close:before {
    color: #ffffff;
  }
  .el-message-box__content {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .el-message-box__btns {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 80px;
      height: 36px;
    }
  }
  .el-message-box__btns button:nth-child(1) {
    border: 1px solid #262963 !important;
    border-color: #262963 !important;
    color: #d3d3d3;
    background-color: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
.delivery-advertisement-container {
  height: 95%;
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 22px;
  ::-webkit-scrollbar {
    display: none;
  }
  .advertisement-title {
    margin: 19px 0;
  }
  ::v-deep
    .genera-breadcrumb
    .el-breadcrumb__item:last-child
    .el-breadcrumb__inner {
    color: rgb(235, 235, 235);
  }
  .advertisement-main {
    height: 95%;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
    border-radius: 6px;
    padding: 11px 20px 0 0;
    display: flex;
    flex-direction: column;
    .main-btn {
      display: flex;
      justify-content: flex-start;
    }

    .ad-money {
      display: flex;
    }
    .el-table {
      ::v-deep .el-checkbox__inner {
        background: transparent;
      }
      background: none;
      ::v-deep th.el-table__cell.is-leaf {
        border-bottom: none !important;
      }
      ::v-deep .el-table__body-wrapper {
        background: #070932;
      }
      ::v-deep td.el-table__cell {
        border-bottom: 1px solid #262963;

        &:first-child {
          border-left: 1px solid #262963;
        }

        &:last-child {
          border-right: 1px solid #262963;
        }
      }
    }
    ::v-deep .el-table__body tr:hover > td {
      background-color: #0c0e3f !important;
    }
    ::v-deep .el-table__fixed-right-patch {
      width: 0 !important;
      height: 0 !important;
    }
    ::v-deep .el-table__fixed-right::before,
    .el-table__fixed::before {
      content: none;
    }
    ::v-deep .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      background: none;
    }
    .pages-center {
      background: none;
      padding: 0px 0px !important;
    }
    ::v-deep .el-pagination {
      .btn-next,
      .btn-prev {
        background: none;
      }
    }
  }
}
</style>
